<template>
  <div id="app">
    <div v-if="active">
      <alert
        :message="notice"
        :type="notice_type"
        :show="notice != '' ? true : false"
      ></alert>
      <div v-if="!login">
        <login></login>
      </div>
      <div v-else>
        <nav id="sidebar">
          <ul>
            <li><router-link class="link" to="/">Übersicht</router-link></li>
            <li>
              <router-link class="link" to="/settings"
                >Einstellungen</router-link
              >
            </li>
            <li><a @click="logout()">Abmelden</a></li>
          </ul>
        </nav>
        <article id="content">
          <div id="mobile_nav">
            <button
              :class="['menu', { opened: nav_opened }]"
              @click="nav_opened = !nav_opened"
              aria-label="Main Menu"
              :aria-expanded="nav_opened"
            >
              <svg width="50" height="50" viewBox="0 0 100 100">
                <path
                  class="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path class="line line2" d="M 20,50 H 80" />
                <path
                  class="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
             <transition name="mobile"> 
              <ul v-if="nav_opened" id="mobil_links">
                <li>
                  <router-link to="/">Übersicht</router-link>
                </li>
                <li>
                  <router-link to="/settings"
                    >Einstellungen</router-link
                  >
                </li>
                <li><a @click="logout()">Abmelden</a></li>
              </ul>
             </transition> 
          </div>
          <router-view></router-view>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import login from "./views/login.vue";
import alert from "./components/alert";
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState(["login", "notice", "notice_type", "active"]),
  },
  components: {
    login,
    alert,
  },
  methods: {
    logout() {
      this.$store.commit("logout");
    },
  },
  mounted() {
    let key = localStorage.getItem("key");
    let vailed = localStorage.getItem("vailed_until");
    if (key != undefined && vailed != undefined) {
      console.log(key);
      vailed = new Date(vailed).getTime();
      console.log(vailed);
      console.log(Date.now());
      if (Date.now() >= vailed) {
        console.log("abgelaufen");
        this.$store.commit("logout");
        this.$store.commit("active", true);
      } else {
        this.$store.commit("login", {
          key: key,
          vailed: localStorage.getItem("vailed_until"),
          name: localStorage.getItem("name"),
          email: localStorage.getItem("email"),
          role: localStorage.getItem("role"),
          update: localStorage.getItem("update"),
          twofa: Boolean(localStorage.getItem("twofa")),
        });
        this.$store.commit("active", true);
      }
    } else {
      this.$store.commit("active", true);
    }
  },
  data() {
    return {
      nav_opened: false,
    };
  },
};
</script>

<style>
</style>
