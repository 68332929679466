<template>
  <div id="alert">
    <transition name="bounce">
      <p :class="['alert', type, 'fehler']" v-if="show">
        {{ message }}
      </p>
    </transition>
  </div>
</template>
<script>
export default {
  name: "alert",
  props: ["message", "type", "show"],
};
</script>
<style>

.bounce-enter-active {
    animation: alertopen .3s;
}
.bounce-leave-active {
  animation: alertopen .3s reverse;
}
@keyframes alertopen {
    from {
        transform: translate(0, -50px)
    }
    to {
        transform: translate(0, 0)
    }
}
.fehler {
    position: absolute !important;
    z-index: 6;
    width: 97%;
    margin: 1.5%;
}
</style>