<template>
  <div id="settings">
    <h2>Einstellungen</h2>
    <div class="card">
      <h5 class="card-header">Übersicht</h5>
      <div class="card-body">
        Name: {{ name }}<br />
        Email: {{ email }}<br />
        Update: {{ update }}<br />
        Key: {{ key }}
      </div>
    </div>
    <div class="card">
      <h5 class="card-header">2 Faktor Authentifizierung</h5>
      <div class="card-body">
        <button
          class="btn btn-success"
          @click="activate_twofa()"
          v-if="!twofa_new"
        >
          Aktivieren
        </button>
        <div v-else>
          <button class="btn btn-danger" @click="deactivate_twofa()">
            Deaktivieren
          </button>
          <div v-if="img != ''">
            <p>Scanne den Qr Code mit Google Authenticator:</p>
            <br />
            <img :src="img" />
            <button class="btn btn-success" @click="$store.commit('logout')">
              Abmelden
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <h5 class="card-header">Passwort zurücksetzen</h5>
      <form class="card-body">
        <label>Altes passwort:</label><br />
        <input class="form-control" v-model="old_pass" type="password" /><br />
        <label>Neues passwort:</label><br />
        <input class="form-control" v-model="new_pass1" type="password" /><br />
        <label>Neues passwort wiederholen:</label><br />
        <input class="form-control" v-model="new_pass2" type="password" /><br />
        <button class="btn btn-warning" @click="change_password()">
          Jetzt zurücksetzen
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "settings",
  computed: {
    ...mapState(["key", "vailed", "name", "update", "email", "twofa"]),
  },
  mounted() {
    this.twofa_new = this.twofa;
  },
  data() {
    return {
      twofa_new: false,
      img: "",
      old_pass: "",
      new_pass1: "",
      new_pass2: "",
    };
  },
  methods: {
    async activate_twofa() {
      try {
        let result = await this.$http({
          method: "post",
          url: this.$server + "/activate_twofa",
          data: {
            key: this.key,
          },
        });
        console.log(result);
        this.img = result.data.img;
        this.twofa_new = !this.twofa_new;
      } catch (e) {
        this.$store.dispatch("handel_error", e);
      }
    },
    async deactivate_twofa() {
      try {
        await this.$http({
          method: "post",
          url: this.$server + "/deactivate_twofa",
          data: {
            key: this.key,
          },
        });
        this.twofa_new = !this.twofa_new;
        this.$store.commit("logout");
      } catch (e) {
        this.$store.dispatch("handel_error", e);
      }
    },
    async change_password() {
      try {
        if (this.old_pass == "")
          throw new Error("Das alte Passwort ist nicht angegeben");
        else if (this.new_pass1 == "")
          throw new Error("Das neue Passwort ist nicht angegeben");
        else if (this.new_pass2 == "")
          throw new Error("Das neue Passwort wurde nicht wiederholt");
        else if (this.new_pass1 != this.new_pass2)
          throw new Error("Das neue Passwort wurde nicht richtig wiederholt");
        let message = await this.$http({
          method: "post",
          url: this.$server + "/changepassword",
          data: {
            key: this.key,
            old: this.old_pass,
            new: this.new_pass2,
          },
        });
        if (message.data.status == "ok") {
          alert("Passwort wurde zurückgesetzt");
          this.old_pass = "";
          this.new_pass1 = "";
          this.new_pass2 = "";
        }
      } catch (e) {
        this.$store.dispatch("handel_error", e);
      }
    },
  },
};
</script>
<style>
</style>