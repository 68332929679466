<template>
  <div id="overview">
    <div class="block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            Alle Verbindungen
          </li>
        </ol>
      </nav>
      <h5>Deine Geräte</h5>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Ip</th>
              <th>Verbrauch</th>
              <th>Maximaler Verbrauch</th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="open(connection.id)"
              v-for="connection in connections"
              :key="connection.id"
            >
              <td>{{ connection.name }}</td>
              <td>{{ connection.ip }}</td>
              <td>{{ current(connection.current) }} MB</td>
              <td>{{ max_connection(connection.max) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "overview",
  data() {
    return {
      connections: [],
    };
  },
  async mounted() {
    try {
      let result = await this.$http({
        method: "post",
        url: this.$server + "/overview",
        data: {
          key: this.key,
        },
      });
      console.log(result);
      this.connections = result.data.connections;
    } catch (e) {
      this.$store.dispatch("handel_error", e);
    }
  },
  computed: {
    ...mapState(["key", "vailed", "name"]),
  },
  methods: {
    max_connection(value) {
      if (value == -1) {
        return "unbegrenzt";
      } else {
        return `${Math.round((value / 1048576) * 100) / 100} MB`;
      }
    },
    current(value) {
      return Math.round((value / 1048576) * 100) / 100;
    },
    open(id) {
      this.$router.push({ name: "detailed_view", params: { id: id } });
    },
  },
};
</script>
<style>
</style>