<template>
  <div id="detailed_view">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">
          <router-link class="link" to="/">Alle Verbindungen</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ connection.name }}
        </li>
      </ol>
    </nav>
    <h5>{{ connection.name }}</h5>
    <chartist
      v-if="connection.max != -1"
      ratio="ct-chart"
      type="Pie"
      :data="pie_data"
      :options="chartOptions"
    ></chartist>
    <div class="card">
      <h5 class="card-header">Übersicht</h5>
      <div class="card-body">
        Name: {{ connection.name }} <br />
        Ip: {{ connection.ip }} <br />
        Max: {{ max_connection(connection.max) }} <br />
        Derzeitiger Verbrauch: {{ max_connection(connection.current) }} <br />
      </div>
    </div>
    <div class="card">
      <h5 class="card-header">Die Letzten 24h</h5>
      <div class="card-body">
        <chartist
          class="my_cahart"
          ratio="ct-chart"
          type="Line"
          :data="las24hours_data"
          :options="chartOptions"
        >
        </chartist>
      </div>
    </div>
    <div class="card">
      <h5 class="card-header">Dieser Monat</h5>
      <div class="card-body">
        <chartist
          class="my_cahart"
          ratio="ct-chart"
          type="Bar"
          :data="month_data"
          :options="month_options"
        >
        </chartist>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "detailed_view",
  data() {
    return {
      id: this.$route.params.id,
      connection: {},
      las24hours_data: {},
      month_data: {},
      notice: "",
      chartOptions: {
        fullWidth: true,
        low: 0,
        showArea: true,
        chartPadding: {},
      },
      month_options: {
        low: 0,
        showArea: true,
        chartPadding: {},
      },
    };
  },
  computed: {
    ...mapState(["key", "vailed", "name"]),
    pie_data() {
      let percentage = (this.connection.max / this.connection.current) * 100;

      return {
        series: [
          Math.round((percentage / 1048576) * 100) / 100,
          Math.round((100 - percentage / 1048576) * 100) / 100,
        ],
      };
    },
  },
  methods: {
    max_connection(value) {
      if (value == -1) {
        return "unbegrenzt";
      } else {
        return `${Math.round((value / 1048576) * 100) / 100} MB`;
      }
    },
    current(value) {
      return Math.round((value / 1048576) * 100) / 100;
    },
  },
  components: {},
  async mounted() {
    console.log(this.cahrtdata);
    try {
      let result = await this.$http({
        method: "post",
        url: this.$server + "/connection",
        data: {
          key: this.key,
          id: this.id,
        },
      });
      console.log(result);
      this.connection = result.data.connection;
      this.las24hours_data = result.data.las24hours;
      this.month_data = result.data.this_month;
    } catch (e) {
      this.$store.dispatch("handel_error", e);
    }
  },
};
</script>
<style>
.my_cahart {
  height: 30%;
}
</style>