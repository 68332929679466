<template>
  <div id="login">
    <div class="center">
      <div class="loginform">
        <h3>Login</h3>
        <form v-if="!twofa">
          <input
            class="form-control"
            type="email"
            v-model="email"
            placeholder="Username (Email)"
          />
          <input
            class="form-control"
            type="password"
            v-model="password"
            placeholder="Password"
          /><br />
          <button class="btn btn-primary" @click="login()">Login</button>
        </form>
        <form v-else>
          <input
            type="number"
            class="form-control"
            v-model="twofa_code"
            placeholder="2FA Code"
          /><br />
          <button class="btn btn-primary" @click="login()">Senden</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      notice: "",
      twofa: false,
      twofa_code: undefined,
    };
  },
  methods: {
    async login() {
      this.$store.commit("remove_notice");
      if (
        (this.email == "" && this.password == "") ||
        this.email == "" ||
        this.password == ""
      ) {
        this.$store.commit("notice", "Du hast nicht alle Felder ausgefüllt !");
        return;
      }
      let result;
      try {
        result = await this.$http({
          method: "post",
          url: this.$server + "/authenticate",
          data: {
            email: this.email,
            password: this.password,
            twofa_code: this.twofa_code,
          },
        });
        console.log(result);
        if (result.data.status == "twofa") this.twofa = true;
        else {
          result.data.twofa = this.twofa;
          this.$store.commit("login", result.data);
        }
      } catch (e) {
        if (e.message != undefined) {
          if (e.response.data != undefined && e.response.data.message != undefined) {
            this.$store.commit("notice", e.response.data.message);
          } else {
            this.$store.commit("notice", e.message);
          }
        } 
        console.log(e);
      }
    },
  },
};
</script>
<style>
.center {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.loginform {
  text-align: center;
  width: 100%;
  max-width: 330px;
}
input {
  background-color: transparent;
}
button {
  width: 100%;
}
</style>