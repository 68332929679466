import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        name: undefined,
        email: undefined,
        login: false,
        key: undefined,
        role: undefined,
        update: undefined,
        vailed: undefined,
        twofa: false,
        notice: '',
        notice_type: '',
        active: false
    },
    mutations: {
        login(state, data) {
            state.login = true
            state.key = data.key
            state.name = data.name
            state.email = data.email
            state.role = data.role
            state.update = data.update
            state.vailed = data.vailed
            state.twofa = data.twofa
            localStorage.setItem('key', data.key)
            localStorage.setItem('name', data.name)
            localStorage.setItem('email', data.email)
            localStorage.setItem('role', data.role)
            localStorage.setItem('update', data.update)
            localStorage.setItem('vailed_until', data.vailed)
            localStorage.setItem('twofa', data.twofa)
        },
        logout(state) {
            state.login = false
            localStorage.removeItem('key')
            localStorage.removeItem('name')
            localStorage.removeItem('email')
            localStorage.removeItem('role')
            localStorage.removeItem('update')
            localStorage.removeItem('vailed_until')
            localStorage.removeItem('twofa')
        },
        active(state, data) {
            state.active = data
        },
        notice(state, data) {
            if (typeof data == 'string') {
                state.notice = data
                state.notice_type = 'alert-danger'
            } else {
                if (data.notice_type != undefined) state.notice_type = data.notice_type
                state.notice = data.message
            }
        },
        remove_notice(state) {
            state.notice = ''
            state.notice_type = ''
        }

    },
    actions: {
        handel_error({ commit }, e) {
            if (e.message != undefined) {
                if (e.response.data != undefined && e.response.data.message != undefined) {
                    commit("notice", e.response.data.message);
                } else {
                    if (e.response.status != undefined && e.response.status == 401) {
                        commit('notice', { message: 'ausgeloggt', notice_type: 'alert-warning' })
                        commit('logout')
                    } else {
                        commit("notice", e.message);
                    }

                }
            }

        }
    },
    modules: {}
})