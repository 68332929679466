import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import store from './store'

//routes
import Overview from './views/overview.vue'
import Settings from './views/settings.vue'
import Detailed_view from './views/detailed_view.vue'
import './style.css'

import vue_chartist from 'vue-chartist';

import '../node_modules/chartist/dist/chartist.min.css';
import './registerServiceWorker'


Vue.use(VueRouter)
Vue.use(vue_chartist)

Vue.prototype.$http = Axios;
Vue.prototype.$server = 'https://vpn.work-dnw.de/api/'

Vue.config.productionTip = false
const routes = [{
    path: '/',
    name: 'connection',
    component: Overview
}, {
    path: '/settings',
    name: 'settings',
    component: Settings
}, {
    path: '/detailed_view/:id',
    name: 'detailed_view',
    component: Detailed_view
}]

const router = new VueRouter({
    routes
})

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')